.invite-item {
    .invite-details {
        font-size: 16px;
    }

    .extra-details {
        margin-bottom: 10px;
    }
    .invite-header{
        display: flex;
        font-size: 1.1rem;
        font-weight: 600;
        align-items: center;
        margin-bottom: 12px;
    }
    .relative-image-container {
        border: 3px solid rgb(130, 130, 130);
        border-radius: 50%;
        position: relative;
        width: 60px;
        height: 60px;
        overflow: hidden;
        display: inline-block;
        margin-right: 12px;
        cursor: pointer;
    }
    .relative-image {
        display: inline-block;
        border: 2px solid rgb(200, 200, 200);
        border-radius: 50%;
        max-width: 60px;
        max-height: 60px;
        height: 100%;
        margin: 0 auto 10px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-size: contain !important;
        background-position: center !important;

    }
    .relative-avatar{
        display: inline-flex;
        font-size: 1.2rem;
        max-width: 60px;
        max-height: 60px;
        height:100%;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        background-color: #b385ea;
    }

    Card.Meta {
        margin: 400px;
    }
}
