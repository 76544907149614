.sso-container {
    display: flex;
    justify-content: center;
    align-content: center;
    .content-disp {
        margin-top: 50%;
        font-size: 25px;
    }

    .input {
        height: 30px;
        margin: 5px 5px;
    }

    .loading {
        margin-top: 50%;
        font-size: 25px;
    }

    .loading:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4, end) 900ms infinite;
        animation: ellipsis steps(4, end) 900ms infinite;
        content: '\2026'; /* ascii code for the ellipsis character */
        width: 0px;
    }

    @keyframes ellipsis {
        to {
            width: 20px;
        }
    }

    @-webkit-keyframes ellipsis {
        to {
            width: 20px;
        }
    }
}
