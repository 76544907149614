.alexa-invite-link-sso {
    display: flex;
    justify-content: center;
    align-content: center;
    .content-disp {
        margin-top: 50%;
        font-size: 25px;

        .instruction {
            padding: 10px 10px 10px 10px;
            font-size: 25px;
        }

        .message {
            padding: 30px 10px 10px 10px;
            font-size: 25px;
        }
    }

    .input {
        height: 30px;
        margin: 35px 5px;
    }

    .loading {
        margin-top: 50%;
        font-size: 25px;
    }

    .done {
        margin-top: 50%;
        font-size: 25px;
    }

    .loading:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4, end) 900ms infinite;
        animation: ellipsis steps(4, end) 900ms infinite;
        content: '\2026'; /* ascii code for the ellipsis character */
        width: 0px;
    }

    @keyframes ellipsis {
        to {
            width: 20px;
        }
    }

    @-webkit-keyframes ellipsis {
        to {
            width: 20px;
        }
    }
}
.alexa-invite-accept-page {
    .title-section {
        font-size: 1.6em;
        margin: 0.6em 0 0.8em;
    }
    .message-title {
        padding-top: 10px;
        font-size: 1.1em;
        margin: 0.6em 0 0.8em;
    }
    .message {
        margin-top: 1em 0;
        margin-bottom: 2em;
        height: 180px;

        // max-width: 600px;
        max-height: 180px;
        overflow-y: scroll;
        background-color: whitesmoke;
        padding: 1em;
        border-radius: 4px;
    }
    .checkbox-section {
        display: flex;
        justify-content: space-between;
        margin: 1.5em 0;
        .button {
            min-width: 68px;
        }
    }
    .confirmation {
        font-size: 3em;
        margin-top: 100px;
        text-align: center;
    }
}

.alexa-user-payment-page {
    padding-bottom: 80px;
    .speak2-logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .blue-text {
        color: #23496d;
        padding: 10px;
    }
    .message-title {
        padding-top: 10px;
        font-size: 1.1em;
        margin: 0.6em 0 0.8em;
    }
    .message {
        margin-top: 1em 0;
        margin-bottom: 2em;
        height: 180px;

        // max-width: 600px;
        max-height: 180px;
        overflow-y: scroll;
        background-color: whitesmoke;
        padding: 1em;
        border-radius: 4px;
    }
    .center-align {
        text-align: center;
    }
    .one-four-em {
        font-size: 1.4em;
    }
    .link-button {
        background: #e98530;
        cursor: pointer;
        border-radius: 8px;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        color: white;
        border-color: white;
    }
    .bar {
        height: 1px;
        border-width: 0;
        color: gray;
        background-color: #6399ba;
    }
    .social-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1em 0;
        > a {
            padding: 0 4px;
        }
    }
}
