.category-breakfast .fc-event-title {
    color: #2987CD;
}

.category-lunch .fc-event-title {
    color: #69B546;
}

.category-dinner .fc-event-title {
    color: #EAC31F;
}

.category-snack .fc-event-title {
    color: #E98530;
}

.category-alternative .fc-event-title {
    color: #E98530;
}

.menu-item-modal {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

    .menu-modal-image {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .menu-modal-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 500px;
    }

    .desc-item {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .label {
            flex: 0 0 auto;
            width: 120px;
            text-align: right;
            margin-right: 10px;
            border: none;
        }
    }

    .desc-item .description {
        flex: 1 1 auto;
    }
}

.calendar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.menu-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.menucard-container {
    max-height: 70vh;
    overflow-y: auto;
    padding: 0px 20px;
}

.hover-enlarge-card {
    width: 80% !important;
    background: #cbeafc !important;
    transition: transform 0.3s !important;
}

.hover-enlarge-card:hover {
    transform: scale(1.03);
}