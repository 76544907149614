.downloads-page{
    text-align: center;
    .logo{
        max-width: 260px;
        margin-top: 14vh;
        h4{
            margin-bottom: 8px;
        }
    }
    
}
.invites.login-wrapper{
    text-align: center;
}
.app-links{
    display: flex;
    align-items: center;
    justify-content: center;
}
.app-store{
    img{
        width: 144px;
    }
}
.play-store{
    img{
        width: 180px;
    }
}