.notification-retry-message {
    color: #e67e22;
    font-size: 0.8rem;
    padding-top: 4px;
    padding: 6px 12px;
}
.notification-retry-button {
    text-align: center;
}
.notification-bg {
    background: #f1c40f;
}
.suppressed-news {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 20px;
}
.request-view {
    display: flex;
    max-width: 500px;
    margin: 0 auto;
    border: 1px solid #3d9cbf;
    border-radius: 0.6rem;
    padding: 1em;
    margin-top: 20px;
    .details {
        flex: 1;
        text-align: center;
        font-size: 1rem;
        .timestamp {
            margin-top: 4px;
            color: #a0a0a0;
        }
        .resident-name {
            margin-top: 4px;
            color: #a0a0a0;
        }
        .title {
            font-size: 1.1rem;
            font-weight: 600;
        }
        .status {
            color: #a0a0a0;
            font-size: 1rem;
            margin-top: 8px;
            span {
                font-weight: 600;
                color: #198f35;
            }
            .Open {
                color: #f2711c;
            }
            span.name {
                color: unset;
            }
        }
    }

    .img {
        max-width: 80px;
    }
    .mb-12 {
        margin-bottom: 12px;
    }
    .circular-image {
        border-radius: 50%;
    }
}
.request-detail {
    .requestControl {
        padding-top: 8px;
        .ui.primary.tiny.button {
            margin: 2px;
        }
    }
    .detail {
        color: #e67e22;
        font-size: 0.8rem;
        padding-top: 4px;
    }
    .actions {
        // Negative margin makes the actions div strech 16px on the sides so that the buttons can be accomodated
        margin: 0 -16px;
    }
    .archive-button-icon {
        height: 1em !important;
        margin-bottom: -0.12em !important;
    }
    .mb-4 {
        margin-bottom: 4px;
    }
}
@media screen and (max-width: 600px) {
    .request-view {
        .img {
            max-width: 40px;
        }
    }
}
