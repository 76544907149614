.invite-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    .full-width {
        width: 100%;
    }
    .disabled {
        color: rgba(191, 191, 191, 0.87);
    }
}
.ml-8 {
    margin-left: 8px;
}
@media screen and (max-width: 767px) {
    width: 100%;
}

// PhoneInputCountry > .PhoneInputCountrySelect > .ui.form .disabled.fields .field,
// .ui.form .disabled.field,
// .ui.form .field :disabled {
//     opacity: 0 !important;
// }

// .PhoneInputInput > .ui.form .disabled.fields .field,
// .ui.form .disabled.field,
// .ui.form .field :disabled {
//     opacity: 0.45 !important;
// }

.phoneInputDisabled {
    .PhoneInputCountrySelect {
        opacity: 0 !important;
    }

    .PhoneInputInput {
        opacity: 0.45 !important;
    }
}
