/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
    height: 40px;
    padding: 10px 12px;
    width: 420px;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 10px;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}
.checkout-card-inline {
    padding: 16px;
    border: 1px solid #e6ebf1;
    border-radius: 8px;
    max-width: 660px;
    h4 {
        color: #32325d;
        margin-bottom: 12px;
    }
    .card-details {
        display: flex;
        margin-bottom: 12px;
        .cardName {
            margin-right: 8px;
            border: none;
            color: #32325d;
            input {
                border: 1px solid transparent;
                border-radius: 10px;
                box-shadow: 0 1px 3px 0 #e6ebf1;
            }
            input:focus {
                box-shadow: 0 1px 3px 0 #cfd7ef;
                border-color: transparent;
            }
        }
    }
    @media only screen and (max-width: 660px) {
        .card-details {
            flex-wrap: wrap;
        }
        .cardName {
            margin-bottom: 8px;
        }
    }
}
@media only screen and (max-width: 420px) {
    .StripeElement {
        width: 300px;
    }
}
.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}
