.directory-container {
    padding: 0;
    margin: 0;
    border: 1px solid #183466;
    height: 80vh;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .heading {
        margin-top: 10px;
        margin-left: 40px;
        height: 43px;
        width: fit-content;
        border-bottom: 2px solid #183466;
        margin-bottom: 10px;
    }

    .search {
        margin-left: 40px;
        margin-top: 10px;
    }

    .directory-items {
        padding: 0px 10px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }

    .division-one {
        margin: 0px 20px;
        overflow-y: auto;
    }

    .directory-item {
        margin-top: 10px !important;
        height: auto;
        cursor: pointer;
    }

    .directory-item-header {
        margin-left: -20px !important;
    }

    .directory-item-image {
        width: 70px;
        margin-right: 10px;
    }

    .load-more-button {
        margin: 10px;
        display: flex;
        justify-content: center;
    }
}

.resident-modal-item {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

    .resident-modal-image {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .resident-modal-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 500px;
    }

    .desc-item {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .label {
            flex: 0 0 auto;
            width: 100px;
            text-align: right;
            margin-right: 10px;
            border: none;
        }
    }

    .desc-item .description {
        flex: 1 1 auto;
    }
}