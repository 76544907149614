.invites {
    width: 100%;
    padding: 0.8rem 1.2rem 0;
    position: relative;
}

.no-invites-text {
    font-size: 2em;
    margin-top: 40px;
    line-height: 1.14;
    color: #ddd;
}
.invite-accept-modal {
    position: relative;
    .content {
        padding: 1rem;
        textarea {
            resize: none;
        }
    }
    .actions {
        padding-bottom: 1rem;
    }
    .close.icon {
        color: rgb(160, 160, 160) !important;
    }
}

@media screen and (max-width: 767px) {
    .no-invites-text {
        font-size: 1.4em;
        margin-top: 20px;
        line-height: 1.14;
        color: #bbbbbb;
    }
}
