.nameContainerMenu {
    margin: 5px 5px;
    background-color: #2D2B87;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 15px 0px;
    cursor: pointer;
    height: 43px;
    width: 25%;
    float: right;
}

.noMenuContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 25px;
    margin-top: 30px;
}

.nameContainerOrder {
    margin: 5px 5px;
    background-color: #2D2B87;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 15px 0px;
    cursor: pointer;
    height: 43px;
    width: 80%;
}

.center-self-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.center-self-gap {
    display: grid;
    grid-template-columns: repeat(3, 0.3fr);
    align-items: center; 
    width: 100%;
    gap: 10px;
    font-size: 20px;
}

.addon-name {
    color: #5C5D5D;
    margin: 0px;
    width: 20px;
    font-size: 15px;
}

.spacing-half {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 0.5px;
    background-color: black;
}

.restaurantContainer {
    margin-top: 30px;
    margin-left: 80px
}

.top-ten {
    margin-top: 10px;
}
.nameCont {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}
.service-add-on{
    padding: 10px 20px;
}
.restaurant-filter {

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 20px;

    .group-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: "pointer";
    }

    .menu-item {
        margin-bottom: 7px;
        border: 2px solid transparent;
        display: flex;
        justify-content: space-evenly;
        background-color: white;
    }

    .menu-item.active.item {
        background-color: white;
        border: 2px solid #34a5d0;
        border-radius: 5px;
    }

    .ui.menu .menu {
        display: flex;
        justify-content: space-between;
    }

    .ui.menu {
        border: none;
        box-shadow: none
    }

    .carousel-arrows-container {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    .angle {
        cursor: pointer;
    }

    .vertical-divider {
        width: 1px;
        background-color: #d4d4d5;/* Adjust the color as needed */
        margin: 0 1rem;/* Adjust the margin as needed */
    }
}