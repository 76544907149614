.link-resident-list {
    padding-bottom: 80px;
    .resident-image-container {
        display: inline-block;
        border: 4px solid rgb(200, 200, 200);
        border-radius: 50%;
        width: 13vw;
        height: 13vw;
        max-width: 60px;
        max-height: 60px;
        position: relative;
        cursor: pointer;
        margin: 0 auto;

        img {
            display: inline-block;
            height: 100%;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            user-select: none;
        }
    }
    .relative-avatar {
        background-color: #b385ea;
        font-weight: 600;
        border-radius: 50%;
        display: inline-flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 1.1rem;
        text-transform: capitalize;
        position: absolute;
    }
    .resident-image-bk {
        background-repeat: no-repeat;
        background-size: cover !important;
        height: 100%;
        border-radius: 50%;
        flex: 1;
    }
    .resident-list-item {
        display: flex;
        // max-width: 420px;
        align-items: center;
        margin: 8px 0;
        border-radius: 6px;
        padding: 8px 12px;
        border: 1px solid #efefef;
        box-shadow: 0px 2px 2px #efefef;

        .name-section {
            flex: 1;
            margin-left: 8px;
            font-weight: 600;
            font-size: 1.1em;
            margin-top: -8px;
        }
    }
    .add-user-section {
        margin-top: 20px;
        text-align: center;
    }
}
