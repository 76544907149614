.circle {
  width: 7.5vw;
  height: 7.5vw;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.self-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-button {
  position: absolute;
  top: 0.8rem;
  left: 5;
  z-index: 1;
}

.styled-input {
  border-radius: 10px;
  max-width: 50rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  cursor: pointer;
  position: relative;
  /* Needed for positioning the loader */
}

.icon-wrapper {
  margin-right: 0.7rem;
}

.input-placeholder {
  flex: 1;
  color: #999;
}

.loader-wrapper {
  position: absolute;
  right: 1rem;
}

.memberList {
  max-height: 80vh;
  overflow-y: auto;

  .directory-items {
    padding: 0px 50px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  .directory-item {
    margin: 10px !important;
    padding: 10px 30px;
    height: auto;
    cursor: pointer;
  }

  .directory-item-header {
    margin-left: -20px !important;
  }

  .directory-item-image {
    width: 70px;
    margin-right: 10px;
  }
}