.subscription-payment-details {
    padding: 16px;
    border: 1px solid #e6ebf1;
    border-radius: 8px;
    max-width: 660px;
    position: relative;

    > strong {
        color: #2b3864;
    }
    h4 {
        color: #32325d;
        margin-bottom: 12px;
    }
    .typography-body4 {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
    }
    .payment-detail {
        margin: 10px 0;
        .card-details {
            display: flex;
            align-items: center;
            p {
                margin-bottom: 0;
            }
            > * {
                width: 150px;
            }

            .card-number {
                display: flex;
                align-items: center;
            }

            .card-type-icon {
                margin-right: 12px;
            }

            .card-expiry::before {
                content: 'Expires ';
            }
            .edit-card {
                flex: 1;
                text-align: right;
            }
        }
        @media screen and (max-width: 600px) {
            .card-details {
                flex-wrap: wrap;
                p {
                    margin-bottom: 4px;
                }
                .card-name {
                    width: 100%;
                }
                .edit-card {
                    width: 20px;
                }
            }
            margin: 0;
        }
    }
    p.faded {
        margin-bottom: 10px;
    }
    .payment-detail:not(:last-child) {
        margin-bottom: 20px;
    }
}

.plus-active-after-trial-disclaimer {
    background: #6fcf97;
    border-radius: 6px;
    color: #ffffff;
    padding: 10px 12px;
    margin: 10px auto;
    text-align: center;
}

.spacer-after-trial-message {
    margin: 5px auto;
}
.custom-multi-state-button {
    display: flex;
    align-items: center;
    justify-content: center;
}
.downgrade-warning-message {
    background-color: rgba(235, 87, 87, 0.1);
    border-radius: 6px;
    color: #eb5757;
    text-align: center;
    padding: 10px;
    margin-top: 10px;
}

.past-due-warning-message {
    background-color: rgba(235, 87, 87, 0.1);
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    color: #eb5757;
    text-align: center;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.payment-border-error {
    border-color: red;
    border-width: 2px;
    border-style: solid;
}
