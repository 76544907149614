.center {
    text-align: center;
}

.docsContainer {
    margin: 20px 0;
    max-height: 50vh;
    overflow: auto;
}

.nonBold {
    font-weight: 100;
}

h4 {
    margin: 10px 0;
}

.ui.segment {
    margin: 0;
}