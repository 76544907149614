.dropzone p{
    min-height: 260px;
    background-color: #ececec;
    text-align: center;
    font-size: 1.8rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #ddd;
}
.upload-image-container{
    text-align: center;
}
.image-success{
    color: #18ac59;
    font-weight: 600;
}
.image-error{
    color: #b10702;
    font-weight: 600;
}
.relative-image-container{
    position: relative;
}
.relative-image{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: contain !important;
    background-position: center !important;
}