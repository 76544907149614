.password-field {
    display: flex;
    flex-direction: row;
    width: 100%;

    .progress {
        background: #ffffff !important;
    }
    .full-width {
        width: 100%;
    }
    .full-75 {
        width: 75%;
    }
}

.mr-8 {
    margin-right: 8px;
}
.mt-8{
    margin-top: 8px;
}

.no-message-recipients {
    font-size: 2.4em;
    margin-top: 80px;
    line-height: 1.14;
    color: #ddd;
    text-align: center;
}
.add-photo-icon{
    .icon{
        margin-left: 4px;
        margin-top: -2px;
    }
}
.message-recipient-details{
    .ui.form{
        .error.message{
            display: block;
        }
    }
}