@import '../../../semantic-ui/site/globals/site.variables';

.activity-list {
    padding-bottom: 60px;
}
.activity-container {
    display: flex;
    margin: 12px 0;
    border-bottom: 1px solid #e0e1e2;
    padding-bottom: 0.5rem;
    cursor: pointer;
    .timestamp {
        color: #808080;
        font-size: 1rem;
    }
    .status {
        color: #e67e22;
        font-size: 0.65rem;
        padding: 4px 4px;
    }
    .title {
        color: @primaryColor;
        font-size: 1.14rem;
        padding-top: 4px;
    }
    .detail {
        color: #e67e22;
        font-size: 0.8rem;
        padding-top: 4px;
    }
    .icon-section {
        font-size: 1.2rem;
        font-weight: 600;
        padding: 0 8px;
        color: @primaryColor;
        img {
            width: 40px;
            height: 40px;
        }
    }
    .requestControl {
        padding: 5px 0px;
    }
    .resident-image-section {
        padding-top: 4px;
        padding-right: 4px;

        .resident-image {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }
}

.pagination {
    text-align: center;
    position: relative;
    margin: 32px 0;
    min-height: 80px;
    .ui.active.loader {
        margin-top: 40px;
    }
}
.feed-title-container {
    color: rgb(19, 48, 102);
    font-size: 1.2rem;
    margin-top: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e0e1e2;
    .feed-title {
        padding-right: 20px;
        padding-left: 8px;
    }
}

.feed-title {
    background-color: #6dbd45;
    position: relative;
    .filters-dropdown {
        position: absolute;
        font-size: 1rem;
        right: 8px;
        top: 4px;
    }
    .download-reports {
        position: absolute;
        cursor: pointer;
        left: 8px;
        font-size: 1rem;
    }
    .download-reports:hover {
        opacity: 0.7;
    }
}
@media screen and (max-width: 600px) {
    .feed-title-container {
        font-size: 16px;
    }
    .my-request {
        .icon-section {
            img {
                width: 36px;
                height: 36px;
            }
            .ui.button {
                width: 32px;
                height: 32px;
            }
            .icon {
                font-size: 26px;
            }
            .calendar {
                top: 2px;
            }
        }
    }

    .feed-title {
        margin-top: 16px;
    }
}
