.App {
    width: 100%;
    height: 100%;
}
@media screen and (max-width: 600px) {
    .feed-button-offset {
        left: 160px !important;
    }
}
.introjs-tooltip {
    box-shadow: 0 3px 30px rgba(33, 33, 33, 0.2), 0 0px 0px 2px rgb(43, 56, 100) !important;
    /* box-shadow: 0 0px 0px 2px rgb(43, 56, 100) !important; */
}

.mt-4{
    margin-top: 10px;
}
