.heading {
    text-align: center;
    font-weight: 100;
}

.spacing {
    margin-top: 10px;
    margin-bottom: 10px;
}

.statusFilters {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.headingContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center
}

.mainHeadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%
}

.backIcon {
    cursor: pointer;
}
.cartContainer {
    position: relative;
    display: inline-block;
}

.cartLength {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
}