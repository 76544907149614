
.login-page{
    min-width: 100vw;
    min-height: 100vh;
}
.login-wrapper {
    max-width: 700px;
    margin: 0 auto;
    padding: 5%;

    .logo {
        display: block;
        width: 100%;
        padding: 0 20% 8%;
    }

    h2 {
        font-family: 'Arial';
        font-weight: normal;
        font-size: 2.1rem;
        line-height: 3rem;
        color: rgb(50, 50, 50);
        display: inline-block;

        @media screen and (max-width: 600px) {
            font-size: 1.8rem;
            line-height: 2.5rem;
        }
    }

    .login-signup-tab > div:nth-child(1) {
        margin-top: 5%;
        margin-bottom: 0 !important;
    }

    .signup-pane,
    .login-pane {
        border: none !important;
    }

    form {
        margin-top: 7%;

        input {
            width: 100% !important;
            padding: 10px !important;
            border: none !important;
            border-radius: 0 !important;
            border-bottom: 3px solid rgb(180, 180, 180) !important;
            margin-bottom: 1.5% !important;
        }

        button {
            width: 90% !important;
            display: block !important;
            padding-top: 20px !important;
            padding-bottom: 20px !important;
            margin: 6% auto 0 !important;
        }
    }
    h4.text-center {
        margin-bottom: 0;
    }
    .signup-container {
        text-align: center;
        margin-top: 20px;
        color: #808080;
        .signup-button {
            width: 90%;
            display: block;
            padding-top: 20px;
            padding-bottom: 20px;
            margin: 0 auto;
            text-align: center;
            margin-top: 8px;
        }
    }
}
.text-center {
    text-align: center;
}
