.filtersContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.statusHeading {
    color: #0E8FD7;
    text-align: center;
    margin-top: 0px;
}

.acceptedPara {
    text-align: center;
    color: gray;
}

.Requests {
    padding: 20px 8px !important;
    border: 2px solid #3D9CBF80;

    .request-header {
        display: flex;
        justify-content: space-between;
        align-items: 'center';
        padding: 0px 20px;

        h2 {
            display: flex;
            align-items: center;
            font-size: 16px;
            margin-bottom: 5px;
        }

        .selected-view-icon {
            background-color: #2987cd;
            color: white;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px
        }
    }

    .request-container {
        padding: 0px 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .calender-container {
        padding-top: 20px;
        font-size: 3em;
        line-height: 1.14;
        text-align: center;
    }

    .no-requests {
        // padding-top: 20px;
        font-size: 1.1rem;
        line-height: 1.14;
        color: #5C5D5D;
        text-align: left;
    }
}

.react-datepicker {
    display: flex !important;
}

.status {
    color: red;
    text-align: center;
}