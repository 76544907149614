.alexa-invite-confirmation-page {
    padding-bottom: 80px;
    .speak2-logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .blue-text {
        padding: 10px 0;
        color: #23496D;
        text-align: center;
    }
    .one-five-em {
        font-size: 1.5em;
    }
    .one-four-em {
        font-size: 1.4em;
    }
    .bar {
        height: 1px;
        border-width: 0;
        color: gray;
        background-color: #6399BA;
    }
    .image-section-p {
        padding-top: 1em;
        text-align: center;
    }
    .social-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1em 0;
        > a {
            padding: 0 4px;
        }
    }
    .address {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 10px;
        text-align: center;
    }
    .unsubscribe {
        mso-line-height-rule: exactly;
        font-family: Lato, Tahoma, sans-serif;
        font-size: 12px;
        color: #00A4BD;
        font-weight: normal;
        text-decoration: underline;
        font-style: normal;
    }
}