.feed-parent {
    min-height: 420px;
}
.resident-links-tooltip {
    .introjs-prevbutton {
        font-size: 0.8em;
        padding: 0.2rem 0.6rem;
    }
    .introjs-skipbutton {
        font-size: 26px;
    }
}
