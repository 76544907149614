.my-request {
    .icon-section {
        display: flex;
        align-items: center;
        .ui.button {
            padding: 0;
            width: 40px;
            height: 40px;
            background-color: unset;
        }
        .icon {
            font-size: 32px;
        }
        .check {
            color: #6dbd45;
        }
        .times {
            color: rgb(246, 141, 33);
        }
        .calendar {
            position: relative;
            top: 4px;
        }
        .feed-cal-item {
            color: rgba(0, 0, 0, 0.87);
        }
    }
}
.my-requests-title,
.feed-title {
    text-align: center;
    padding: 6px 0;
    color: #fff;
    font-size: 1.14rem;
}
.my-requests-title {
    background-color: rgb(19, 48, 102);
    position: relative;
    .resident-name {
        position: absolute;
        font-size: 1rem;
        right: 8px;
    }
}

@media screen and (max-width: 600px) {
    .my-requests-title,
    .feed-title {
        margin: 0 -1.2rem;
        font-size: unset;
        padding: 4px 0;
    }
}
