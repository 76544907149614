.headingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.mainHeadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%
}

.spacing {
    margin-top: 10px;
    margin-bottom: 10px;
}

.center-self {
    display: flex;
    justify-content: center;
    align-items: center;
}

.diningName {
    font-size: 20px;
}

.nameContainer {
    margin: 5px 5px;
    background-color: #2D2B87;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 15px 0px;
    cursor: pointer;
}

.nameContainerRest {
    margin: 5px 25px;
}