.relative-list {
    width: 100%;
    padding: 0.8rem 1.2rem 0;
    position: relative;
}
.page.modals {
    .modal {
        .actions {
            text-align: left;
        }
    }
}
.relative-info-container {
    display: flex;
    border-bottom: 2px solid rgb(200, 200, 200);
    padding-bottom: 1.4vh;
    padding-top: 1.4vh;
    .relative-info {
        border-bottom: none;
        padding-bottom: 0;
        padding-top: 0;
    }
}
.relative-info {
    display: flex;
    flex: 1;
    border-bottom: 2px solid rgb(200, 200, 200);
    padding-bottom: 1.4vh;
    padding-top: 1.4vh;
    cursor: pointer;
    .relative-name {
        font-size: 1.2rem;
        color: rgb(140, 140, 140);
        margin-bottom: 0.65rem;
    }
    .relative-name-sm {
        font-size: 0.8rem;
        color: rgb(140, 140, 140);
        margin-bottom: 0.65rem;
    }
    .relative-name-container {
        flex: 1;
        max-width: calc(100% - 80px);
        vertical-align: bottom;
    }
}
.disabled-relative {
    color: rgb(160, 160, 160);
}
.disabled-list {
    margin-top: 1rem;
}
.relative-detail {
    .relative-info {
        cursor: unset;
        border-bottom: none;
        padding-bottom: 16px;
    }
    .ui.form {
        .field {
            margin-bottom: 0;
        }
    }
    .button-container {
        padding-top: 16px;
        text-align: center;
    }
    .relative-field {
        padding: 0.67857143em 1em;
        border: 1px solid;
        border-color: rgba(34, 36, 38, 0.15);
    }
    .ml-16 {
        margin-left: 16px;
    }
    .alexa-invite {
        padding-top: 6px;
        padding-bottom: 6px;
        input {
            border: none !important;
            padding: 0 !important;
        }
        .field {
            display: inline-block;
            .ui.field {
                display: inline-block;
                width: unset;
            }
        }
    }
    input {
        border-radius: 0 !important;
    }
    .relative-image-container {
        border-style: dashed;
        padding: 4px;
        border-width: 2px;
    }
}
.mt-80 {
    margin-top: 80px;
}
.pt-80 {
    padding-top: 80px !important;
}
.mt-20 {
    margin-top: 20px;
}
.pb-70 {
    padding-bottom: 70px;
}
.mb-02 {
    margin-bottom: 0.2rem;
}
.light-grey {
    color: rgba(34, 36, 38, 0.15);
}
.flex-1 {
    flex: 1;
}
.flex-2 {
    flex: 2;
}
.buy-container {
    position: fixed;
    bottom: 0;
    text-align: center;
    width: 100%;
    max-width: 900px;
    padding: 8px 0;
    background-color: #fff;
}
.invited-title {
    background-color: #eaeaea;
    padding: 6px 8px;
    margin: 8px 0;
}
.relative-image-container {
    border: 4px solid rgb(200, 200, 200);
    border-radius: 50%;
    width: 13vw;
    height: 13vw;
    max-width: 60px;
    max-height: 60px;
    margin-right: 1rem;
    position: relative;
    cursor: pointer;

    img {
        display: inline-block;
        height: 100%;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        user-select: none;
    }

    .relative-avatar {
        background-color: #b385ea;
        font-weight: 600;
        border-radius: 50%;
        display: inline-flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 1.1rem;
        text-transform: capitalize;
    }
}
.invite-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
    margin-left: 15px;
}

.inviteBtn {
    margin-bottom: 15px;
    margin-top: 15px;
}
.ml-8 {
    margin-left: 8px;
}
.invite-desc-text {
    color: #8c8c8c;
    font-size: 1.4rem;
    line-height: 1.42;
    text-align: center;
    margin-top: 2rem;
    padding: 0.8rem 1.2rem;
}
.invite-modal-root {
    .ui.dimmer {
        background-color: rgba(0, 0, 0, 0.5);
    }
    .fnf-invite-modal-content {
        padding: 1.4em;
        .name-section {
            display: flex;
            flex-wrap: wrap;
            .field {
                flex: 1;
                // margin-right: 8px;
            }
            input[name='FirstName'] {
                margin-right: 8px;
            }
        }
        .field,
        textarea {
            margin-bottom: 8px;
        }
    }
}
.ui.dimmer {
    background-color: rgba(0, 0, 0, 0.5);
}
.invite-modal-dimmer {
    background-color: rgba(0, 0, 0, 0.5);
}
.inbound-progress {
    width: 100% !important;
}

.resident-unit-devices{
    display: flex;
    border-bottom: 2px solid rgb(200, 200, 200);
    padding-bottom: 1.4vh;
    padding-top: 1.4vh;
    flex-direction: column;

    .header {
        color: #8c8c8c;
        font-size: 1.4rem;
        line-height: 1.42;
    }

    .devices {
        color: #000000;
        display: flex;
        flex-direction: row;
        justify-content: start;
        
        .device {
            padding-bottom: 1.4vh;
            padding-top: 1.4vh;
            border: 0.1rem solid;
            margin: 5px 5px 5px 5px;
            padding: 5px 5px 5px 5px;
            .device-image {
                width: 20%;
            }
            .model-name {
                font-size: 1rem;
                line-height: 1.42;        
                color: #8c8c8c;
            }
            .serial-number {
                font-size: 0.9rem;
                line-height: 1.42;        
                color: #8c8c8c;
            }
            .friendly-name {
                font-size: 1.4rem;
                line-height: 1.42;        
            }
        
        }
    }
}