.news-page {
    padding: 18px 0;
    .registrant-info-container {
        display: flex;
        justify-content: center;
    }
    .registrant-info {
        display: inline-block;
        position: relative;
        margin: 10px 16px;

        .resident-picture-container {
            text-align: center;
        }

        .resident-picture {
            display: inline-block;
            border: 2px solid rgb(200, 200, 200);
            border-radius: 50%;
            width: 24vw;
            height: 24vw;
            max-width: 120px;
            max-height: 120px;
            margin: 0 auto;
            position: relative;
            background-size: contain !important;
            background-position: center !important;
            cursor: pointer;
            img {
                display: inline-block;
                height: 100%;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                user-select: none;
            }
        }
        .resident-avatar {
            display: inline-flex;
            font-size: 2rem;
            font-weight: 600;
            justify-content: center;
            align-items: center;
            background-color: #b385ea;
            text-transform: capitalize;
            cursor: pointer;
        }

        .registrant-name {
            text-align: center;
            font-size: 1.2rem;
            text-transform: capitalize;
            color: rgb(140, 140, 140);
        }
    }

    .responses-text {
        text-align: center;
        color: #8c8c8c;
        font-size: 1.2rem;
        margin-bottom: 8px;
        a {
            margin-right: 2px;
        }
    }
    .create-message {
        padding: 0 40px;
        .story-response {
            position: relative;
            margin-left: 95%;
            background-color: rgb(8, 155, 192);
            border-radius: 70%;
            height: 50px;
            width: 53px;
            font-size: 20px;
            cursor: pointer;
            div {
                position: relative;
                padding-top: 25%;
                text-align: center;
            }
        }

        .input-message {
            border: 2px solid #183466;
            border-radius: 5px;
            padding: 0 0 15px;
            position: relative;

            .input-message-info {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                font-size: 1.7rem;
                height: 15rem;
                padding: 10% 5% 2%;
                cursor: pointer;

                @media screen and (min-width: 992px) {
                    font-size: 2.1rem;
                    height: 17rem;
                }

                p {
                    text-align: center;
                    font-style: italic;
                    color: rgb(140, 140, 140);
                    user-select: none;
                }
            }

            textarea {
                width: 100%;
                height: 15rem;
                resize: none;
                color: rgb(100, 100, 100);
                padding: 10px;
                border: none;

                @media screen and (min-width: 992px) {
                    height: 17rem;
                }
            }
            .preview-image-container {
                margin: 0 auto;
                text-align: center;
            }
            .preview-image {
                max-width: 300px;
                max-height: 200px;
            }

            .recording-state-image {
                height: 70px;
                font-size: 5rem;
                display: flex;
                justify-content: center;
                align-items: center;

                @media screen and (min-width: 992px) {
                    height: 100px;
                    font-size: 6rem;
                }

                i,
                img {
                    display: inline-block;
                    line-height: 1em;
                    color: rgb(52, 166, 209);
                    cursor: pointer;
                }
                img {
                    height: 1em;
                    margin: 0 16px;
                }

                .recording-button {
                    font-size: 0;
                    background-color: red;
                    border: 0;
                    border-radius: 50%;
                    outline: none;
                    cursor: pointer;

                    margin: 20px auto 0;
                    width: 40px;
                    height: 40px;
                    animation-name: pulse-record;
                    animation-duration: 1.5s;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                }
            }
        }

        .buttons {
            display: flex;
            justify-content: center;

            .submitButton {
                display: inline-block;
                border: 2px solid rgb(52, 166, 209);
                border-radius: 4px;
                margin-top: 20px;
                min-width: 125px;
                padding: 1rem 2.2rem;
                font-size: 1.3rem;
                color: rgb(255, 255, 255);
                box-shadow: 0 0 6px 0 rgba(60, 60, 60, 0.3);
                cursor: pointer;
                border-color: transparent;
                background: rgb(52, 166, 209);
            }

            .playButton {
                display: inline-block;
                background: white;
                border-radius: 4px;
                margin-top: 20px;
                min-width: 125px;
                padding: 1rem 2.2rem;
                font-size: 1.3rem;
                color: rgb(200, 200, 200);
                margin-right: 50px;
                outline: none;
            }

            .activeAudio {
                background: linear-gradient(to right, #67b26b, #4ca2cb);
                border: none;
                color: rgb(255, 255, 255);
                cursor: pointer;
            }

            .activeAudio:hover {
                background: linear-gradient(to left, #67b26b, #4ca2cb);
            }

            .activeAudio:active {
                background: radial-gradient(#0d57e0 5%, #2196de 30%, #67b26b 60%, #b2edb5);
            }

            .submitButton:hover {
                color: white;
                border-color: transparent;
                background: rgba(52, 166, 209, 0.85);
            }

            .submitButton:active {
                color: rgb(50, 50, 140);
                background: rgb(52, 166, 209);
                border: 2px solid rgb(52, 166, 209);
                box-shadow: inset 0 0 4px 1px rgba(60, 60, 60, 0.5);
            }
        }
    }
}
.broadcast-modal {
    min-height: 114px;
    .close.icon {
        color: #183466 !important;
    }
    .failed-list {
        padding: 8px 0;
        list-style-type: none;
    }
    .loader {
        color: green;
    }
    .ui.dimmer {
        padding: 30px 0;
        min-height: 90px;
    }
    .message-sent {
        color: green;
        font-weight: 600;
    }
    li {
        display: flex;
        align-items: center;
        padding: 4px 0;
        border-bottom: 1px solid rgb(200, 200, 200);
    }
    li:last-child {
        border-bottom: none;
    }
    .font-weight-600 {
        font-weight: 600;
    }
}

@keyframes pulse-record {
    0% {
        box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3);
    }
    65% {
        box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0.3);
    }
    90% {
        box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0);
    }
}
