.ui.modal > .close {
    top: 10px !important;
    right: 5px !important;
    margin: 0 !important;
    padding: 0 !important;
}

.modal-content {
    padding: 30px 35px 15px 15px;
    position: relative;

    textarea {
        display: block;
        font-size: 1rem;
        color: rgb(130, 130, 130);
        line-height: 1.1rem;
        padding: 10px;
        width: 100%;
        height: 15rem;
        resize: none;
        border: 1px solid rgb(200, 200, 200);
        border-radius: 5px;
    }

    .update-success {
        color: rgb(52, 166, 209);
        margin: 10px 0 5px;
    }

    .submit-message {
        display: inline-block;
        border: 2px solid rgb(52, 166, 209);
        border-radius: 4px;
        margin-top: 20px !important;
        margin-left: 0 !important;
        min-width: 125px;
        padding: 1rem 2.2rem;
        font-size: 1.3rem;
        color: rgb(255, 255, 255);
        box-shadow: 0 0 6px 0 rgba(60, 60, 60, 0.3);
        cursor: pointer;
        border-color: transparent;
        background: rgb(52, 166, 209) !important;
    }

    .submit-message:hover {
        color: white;
        border-color: transparent;
        background: rgba(52, 166, 209, 0.85) !important;
    }

    .submit-message:active {
        color: rgb(50, 50, 140);
        background: rgb(52, 166, 209);
        border: 2px solid rgb(52, 166, 209);
        box-shadow: inset 0 0 4px 1px rgba(60, 60, 60, 0.5);
    }
}
