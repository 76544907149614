.message-recipient-details {
    padding: 0.8rem 1.2rem 0;

    .dropzone p {
        min-height: 260px;
        background-color: #ececec;
        text-align: center;
        font-size: 1.8rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed #ddd;
    }
    .upload-image-container {
        text-align: center;
    }
    .image-success {
        color: #18ac59;
        font-weight: 600;
    }
    .image-error {
        color: #b10702;
        font-weight: 600;
    }
    .relative-field {
        padding: 0;
    }
    .relative-image-container {
        position: relative;
        margin: auto;
        margin-right: 10px;
    }
    .relative-image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: contain !important;
        background-position: center !important;
    }
    .add-user-container {
        padding-bottom: 2.6em;
        .add-alexa-user-button,
        .invite-alexa-user-button {
            background-color: #183466 !important;
            border: 1px solid #69b546;
            > .icon {
                color: #69b546;
                opacity: 1 !important;
            }
        }
        .add-alexa-user-button {
            margin-right: 1em;
        }
    }
    .alexa-user-detail-field {
        padding: 2px 5px;
        // border-bottom: 1px solid rgba(34, 36, 38, 0.15);

        strong {
            color: red;
        }
    }
    .alexa-permissions-section > ul {
        margin: 10px 25px;
    }
    .alexa-permissions-section > p > strong {
        color: red;
    }
    .alexa-permissions-section > a {
        margin: 10px 25px 10px;
    }
    .alexa-permissions-section {
        border-bottom: 1px solid rgba(34, 36, 38, 0.15);

        > p {
            margin-bottom: 0px;
            padding: 4px 8px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.67);
        }
    }
}

.invite-alexa-user-button {
    margin-top: 8px !important;
}
.change-password {
    .field.full-75 {
        margin-bottom: 0 !important;
    }
}

.speak2-checkbox {
    padding: 9px;
}
.message-recipient-details {
    .primary.button.connect-with-alexa-button {
        background-color: #183466;

        .amazon-icon {
            opacity: 1;
            margin-bottom: 6px !important;
        }
    }
    .primary.button.connect-with-alexa-button:hover,
    .primary.button.connect-with-alexa-button:focus {
        background-color: #183466 !important;
    }
}
.message-recipient-details.relative-detail {
    padding-bottom: 140px;
}
