.heading {
    text-align: center;
    font-size: 35px;
    margin-bottom: -10px;
}

.spacing {
    margin-top: 20px;
    margin-bottom: 20px;
}

.recordEventBtn {
    color: #ffffff !important;
    background-color: #ADD8E6 !important;
    text-align: center;
    width: 100%;
    padding-top: 10px;
}

.createEventForm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 75%;
    margin: 0 auto;
}

.locationInput {
    width: 100%;
}

.customDatePickerWidth,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
    width: 100%;
}
.singleEventDesc {
    margin: 10px 0px;
}


.crossIcon {
    font-size: 30px;
    margin: 5px;
}

.eventTitle {
    font-size: 20px;
    font-weight: bold;
    color: #161616;
    max-width: 400px;
}

.eventTimeLocation {
    margin-top: -20px;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 16px;
    color: #505050;
}

.eventDescription {
    font-size: 18px;
    color: #505050;
}

.eventLongDescription {
    color: #505050;
    font-style: italic;
    max-height: 100px;
    max-width: 400px;
    overflow-y: auto;
}

.eventLabel {
    font-size: 14px;
    font-weight: bold;
    color: #1a1d20;
    padding: -10px;
}

.audio-event {
    display: flex;
    align-items: center;
    white-space: normal;
    gap: 2px;
}

.custom-event-content {
    padding: 5px;
    border-radius: 4px;

    .fc-event-title-time {
        white-space: normal; // Allow text to wrap
        overflow: visible; // Ensure overflow is visible
        text-overflow: clip; // Prevent text from being truncated
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        white-space: normal; // Allow text to wrap
        overflow: visible; // Ensure the overflow is visible
        text-overflow: clip; // Text will not be truncated
    }
}

#eventStartTime {
    margin-right: 5px;
    font-weight: bold
}

.fc .fc-scroller {
    position: relative;
}
.menu-calendar-view {
    position: relative;
}