.fnf-signup {
    .content {
        max-width: 620px;
        .info.info-grey {
            align-items: center;
            margin-top: 1.4em;
        }
        .visit-speak2 {
            margin-top: 0.8em;
        }
    }
}
