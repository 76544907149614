.settings-page {
    padding: 1.25rem 1.2rem;

    form {
        .message-period {
            width: 100%;
            border-bottom: 4px solid rgb(200, 200, 200);
            padding-bottom: 15px;

            h4 {
                margin: 10px 0 0 0;
            }

            h2 {
                font-size: 1.5rem;
                color: rgb(150, 150, 150);
                width: 100%;
            }

            h5 {
                margin: 0 0 20px 0;
                font-size: 0.83em;
            }

            .error-period {
                color: rgb(255, 0, 0);
                width: 100%;
            }

            ul {
                display: flex;
                margin: auto;

                li {
                    list-style: none;
                    margin-right: 10px;
                    font-size: 1.2rem;
                    cursor: pointer;
                    padding: 0.7rem 1rem;
                    background: rgb(52, 166, 209);
                    color: #fff;
                    border-radius: 4px;
                    border: solid 1px #20538d;
                    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
                    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
                    transition-duration: 0.2s;
                    user-select: none;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }

                li:hover {
                    background: #356094;
                    border: solid 1px #2a4e77;
                    text-decoration: none;
                }

                li:active {
                    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
                    background: #2e5481;
                    border: solid 1px #203e5f;
                }

                .active-message {
                    background: #1e3566;
                    font-weight: bold;
                }
            }
        }

        .notification-settings {
            padding-bottom: 2rem;
            .fields {
                flex-direction: column;
                width: 100%;
                margin-left: 0;
                margin-bottom: 0;
                .field {
                    padding: 0;
                }
                input,
                input[type='tel']:focus,
                input:focus {
                    border-radius: 0;
                }
                .PhoneInput:first-child {
                    border-radius: 0.28571429rem 0.28571429rem 0 0;
                }
                .field:last-child {
                    input {
                        border-radius: 0 0 0.28571429rem 0.28571429rem;
                    }
                }
                .PhoneInput {
                    border: 1px solid rgba(34, 36, 38, 0.15);
                    padding-left: 0.5em;
                    input {
                        border: none;
                    }
                    .PhoneInputInput {
                        padding-left: 0.5em;
                    }
                }
                .PhoneInput--focus {
                    border-color: #85b7d9;
                }
            }
            .mt-1 {
                margin-top: 1rem !important;
            }
        }
        .popup-button {
            padding: 0 !important;
            border-radius: 50%;
            color: rgb(245, 126, 44);
            background-color: #fff;
            margin-left: 2px;
        }
        .popup-button:hover,
        .popup-button:active,
        .popup-button:focus {
            color: #d95d08 !important;
            background-color: #fff;
        }
    }
    .current-selection {
        width: 100%;
        margin-top: 1rem !important;
        margin-bottom: 2rem !important;
    }
}

.capitalize {
    text-transform: capitalize;
}
