.checkout-page {
    .number-of-users-section {
        display: flex;
        max-width: 600px;
        margin: 1em 0;
        align-items: center;
        .description {
            flex: 1;
            font-size: 1.2em;
            font-weight: 600;
        }

        .increment-decrement {
            margin-left: 8px;
            .ui.input > input {
                width: 45px;
                font-weight: 700;
            }
            input[type='number']::-webkit-inner-spin-button,
            input[type='number']::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }

            input[type='number'] {
                -moz-appearance: textfield;
            }
            .ui.label {
                font-weight: 700;
                cursor: pointer;
                width: 30px;
            }
            .ui.label:hover {
                background-color: #ddd;
                color: #333;
            }
        }
    }
    .secondary-description {
        margin-top: 1.5em;
        font-weight: 600;
    }
    .button.add-card-button {
        margin: 20px 0;
    }
    .action-container {
        margin-top: 1em;
    }
    .logo-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .blue-color {
        color: #2b3864;
    }
    .title {
        text-align: center;
    }
    .underline {
        height: 1px;
        border-width: 0;
        color: gray;
        background-color: #6399ba;
    }
    .bottom-speak2 {
        border-collapse: collapse;
        mso-line-height-rule: exactly;
        color: #1a1939;
        word-break: break-word;
        text-align: center;
        margin-bottom: 0;
        line-height: 135%;
        padding: 10px 20px;

        font-family: Arial, Helvetica, sans-serif;
        font-size: 10px;
    }
    .terms-and-conditions {
        max-height: 140px;
        overflow-y: scroll;
        border: 1px solid #ddd;
        padding: 8px;
        border-radius: 2px;
        margin-top: 1.5em;
        margin-bottom: 8px;
        max-width: 660px;
    }
    .details {
        font-size: 16px;
        margin-top: 16px;
    }
    .download-table {
        margin-left: auto;
        margin-right: auto;
        width: 163px;
    }
    .download-section {
        text-align: center;
        h2 {
            margin-top: 24px;
        }
    }
    .social-section {
        text-align: center;
        margin-top: 16px;
        img {
            margin: 0 8px;
            // display: block;
            // margin-left: auto;
            // margin-right: auto;
        }
    }
    .stripe-section {
        text-align: center;
        font-size: 12px;
    }
    .error {
        color: #db2828;
    }
    .payment-methods-title {
        color: #32325d;
        margin-bottom: 12px;
        border-bottom: 1px solid #e6ebf1;
        max-width: 660px;
        padding-bottom: 2px;
    }
    .card-list {
        list-style: none;
        margin-top: 2em;
        .card-list-item {
            cursor: pointer;
            margin-left: 20px;
            position: relative;
            margin-bottom: 8px;
            max-width: 640px;
        }
        .card-list-item::before {
            content: '';
            border-radius: 50%;
            height: 16px;
            width: 16px;
            margin-top: 4px;
            border: 2px solid #e6ebf1;
            position: absolute;
            left: -20px;
            top: 20px;
        }
        .card-list-item:hover {
            .subscription-payment-details {
                border-color: #a2c2e8;
            }
        }
        .card-list-item:hover::before {
            border: 1px solid #a2c2e8;
        }
        .card-list-item.selected {
            .subscription-payment-details {
                border-color: #25ac58;
                border-width: 2px;
            }
        }
        .card-list-item.selected::before {
            border: 2px solid #25ac58;
            background-color: #2db461;
        }
    }
    .coupon-code-title {
        font-size: 1.2em;
        font-weight: 600;
    }
    .coupon-code {
        margin-right: 1px;
        padding: 10px 0px;
        margin-top: 8px;
        .ui.input > input {
            width: 105px;
        }
        .coupon-text {
            padding: 10px 0;
        }
        .coupon-btn {
            height: 40px;
        }
    }
}
.checkout-modal-root {
    .cardName {
        width: 420px;
    }
    .pay-card {
        margin: 10px 0;
    }
    .error {
        color: #db2828;
        font-weight: 600;
    }
}

.upcoming-line-items,
.upcoming-line-items-ttl {
    display: flex;
    max-width: 600px;
    justify-content: space-between;
    .amnt {
        min-width: 80px;
        text-align: right;
    }
}
.upcoming-line-items-ttl {
    padding-top: 4px;
    border-top: 1px solid #ddd;
    margin: 4px 0px;
    font-weight: 600;
    font-size: 1.2em;
}
