.resident-reply {
    position: relative;
    background: #cce2ff;
    background-color: rgba(191, 215, 247, 0.7);
    padding: 2px 4px;
    border-radius: 4px;
}
.resident-reply::after {
    content: '';
    position: absolute;
    left: 0;
    top: 16px;
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-right-color: #cce2ff;
    border-left: 0;
    border-top: 0;
    margin-top: -8px;
    margin-left: -12px;
}
.relative-message {
    position: relative;
    background: #cce2ff;
    padding: 2px 4px;
    border-radius: 4px;
}
.relative-message::after {
    content: '';
    position: absolute;
    right: 0;
    top: 16px;
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-left-color: #cce2ff;
    border-right: 0;
    border-top: 0;
    margin-top: -8px;
    margin-right: -12px;
}
.message-number-container {
    align-self: center;
    padding: 2px 8px;
    background-color: #34a6d1;
    border-radius: 50%;
    margin-right: 4px;
}
.message-reply.relative {
    // text-align: right;
    display: flex;
    justify-content: flex-end;
    .relative-image-container {
        margin-right: 0;
        margin-left: 1rem;
    }
    .name-container {
        display: inline-flex;
        align-items: flex-end;
        flex-direction: column;
    }
    .options-dropdown {
        .dropdown {
            i.icon {
                margin-left: 4px;
            }
        }
        .edit-message {
            color: rgb(52, 166, 209);
            cursor: pointer;
        }
        .delete-message {
            color: rgb(255, 0, 0);
            cursor: pointer;
        }
    }
}
.message-reply {
    display: flex;
    padding: 1vh 0;
    .name {
        font-size: 1rem;
        color: rgb(140, 140, 140);
        margin-bottom: 0.2rem;
        font-weight: 600;
        line-height: 1.2;
    }
    .relative-message,
    .resident-reply {
        padding: 6px 8px;
        margin-bottom: 4px;
    }
    .timestamp {
        font-size: 0.786rem;
        color: rgb(140, 140, 140);
    }
    .replied-to {
        background-color: rgba(155, 190, 236, 0.7);
        padding: 3px 5px;
        position: relative;
        border-radius: 2px;
        margin: 4px;
        border-left: 4px solid rgba(96, 144, 223, 1);
        padding-left: 6px;
        color: rgba(0, 0, 0, 0.92);
    }
    audio {
        margin-top: 3px;
        max-width: 100%;
    }
    video{
        max-width: 100%;
        max-height: 240px;
    }
    .relative-image-container {
        flex-shrink: 0;
    }
}
.message-reply-input {
    position: fixed;
    bottom: 0;
    max-width: 900px;
    background-color: #fff;
    padding: 6px 1.2rem;
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.12);
    @media screen and (max-width: 900px) {
        max-width: 100vw;
    }

    .input-container {
        display: flex;
        align-items: center;
    }
    textarea {
        width: 100%;
        resize: none;
        padding: 0.5rem;
        height: 5rem;
        border: 1px solid;
        overflow: hidden;
        border-color: #ddd;
        // @media screen and (max-width: 900px) {
        //     max-width: 100vw;
        // }
        @media screen and (max-width: 600px) {
            height: 9rem;
        }
    }
    .overflow {
        overflow: auto;
        min-height: 5rem;
    }
    // textarea:active,
    // textarea:focus {
    //     border-color: #183466;
    //     height: auto;
    //     overflow: auto;
    // }
    .recording-state-image {
        padding: 2px 4px;
        font-size: 1.4rem;
        img {
            width: 48px;
            max-height: 48px;
            cursor: pointer;
        }

        i {
            display: inline-block;
            line-height: 1em;
            color: #1f3566;
            cursor: pointer;
            font-size: 1.2em;
        }
        i:focus {
            font-size-adjust: 2rem;
        }
        @media screen and (max-width: 600px) {
            font-size: 2.8rem;
        }
    }
    .recording-button {
        font-size: 0;
        background-color: red;
        border: 0;
        display: inline-block;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        padding: 6px;
        margin-right: 8px;
        margin-top: 2px;
        animation-name: pulse-record;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
    .recording-audio {
        flex: 1;
        justify-content: center;
        text-align: center;
        font-weight: 600;
        font-size: 1.16rem;
    }
    .audio-exists {
        flex: 1;
        justify-content: center;
        text-align: center;
        line-height: 1;
    }
    .time-count {
        font-weight: 600;
        color: #183466;
    }
    .button.stop-button {
        padding: 8px 10px 8px 12px;
        i.stop.icon {
            margin-right: 0;
        }
    }
    .preview-image {
        max-width: 300px;
        max-height: 200px;
    }
}
.blue-icon-fill {
    fill: #1f3566;
}
.messages-and-replies {
    max-height: 74vh;
    overflow-y: scroll;
    .relative-list {
        padding-top: 14vh;
        padding-bottom: 14vh;
    }
}
.messages-and-replies .relative-list,
.messages-and-replies .relative-list > * {
    -moz-transform: scale(1, -1);
    -webkit-transform: scale(1, -1);
    -o-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
}
.relative-list-container {
    transform: translate(-200, 0);
}

.messages-and-replies-page {
    .no-messages {
        font-size: 2.4em;
        margin-top: 80px;
        line-height: 1.14;
        color: #ddd;
        text-align: center;
    }
}
