.invite-item-list {
    .ui.cards {
        padding: 0 40px;
        margin: 10px 0;

        .ui.card > .content > .header {
            padding-top: 20px;
        }
    }
}

.no-invites {
    font-size: 2.4em;
    margin-top: 80px;
    line-height: 1.14;
    color: #ddd;
    text-align: center;
}
