.ui.grid>.row.customPadding {
    padding: 10px 0;
}

.ui.small.image {
    margin: auto;
}

.center {
    text-align: center;
}

.ui.checkbox.autoMargin {
    margin: auto;
}

.ui.basic.button.customButton {
    width: 24%;
    margin: 0 4px;
}

.page.modals .modal .actions {
    text-align: right;
}

.ui.checkbox.interestCheckBox {
    margin: 0 20px;
}

.circle {
    width: 13vw; /* Adjust size of the container - corresponds to 250px*/
    height: 13vw; /* Adjust size of the container */
    border-radius: 50%; /* Create circular container */
    overflow: hidden; /* Hide overflow content */
    position: relative;
}

.circle img {
    width: 100%; /* Make image fill the container */
    height: 100%; /* Make image fill the container */
    object-fit: cover; /* Ensure image fills container without stretching */
    border-radius: 50%; /* Create circular image */
}

.small-circle {
    width: 6vw; /* Adjust size of the container */
    height: 6vw; /* Adjust size of the container */
    border-radius: 50%; /* Create circular container */
    overflow: hidden; /* Hide overflow content */
    position: relative;
}

.small-circle img {
    width: 100%; /* Make image fill the container */
    height: 100%; /* Make image fill the container */
    object-fit: cover; /* Ensure image fills container without stretching */
    border-radius: 50%; /* Create circular image */
}