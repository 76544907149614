.signup-page-wrapper {
    position: absolute;
    left: 0;
    width: 100%;
    .header {
        font-size: 2rem;
        font-weight: 600;
        padding: 3rem 2rem;
        color: #fff;
        text-align: center;
    }
    .step-1 {
        background-color: rgb(31, 53, 102);
    }
    .step-2 {
        background-color: #e98530;
    }
    .step-3 {
        background-color: #3d9cbf;
    }
    .content {
        max-width: 1600px;
        margin: 0 auto;
        margin-top: 3rem;
        padding: 0 12px;
        position: relative;
        .content-row {
            display: flex;
            justify-items: space-between;
            > div {
                flex: 1;
                padding: 0 1rem;
            }
            .image-column {
                flex: 0.8;
            }
            .message-recipient-details {
                padding: 0;
                .relative-field {
                    border: none;
                }
            }
            .signup-column {
                position: relative;
            }
            .relative-info {
                padding-bottom: 0;
            }
            .button-container {
                text-align: left;
            }
        }
        .info {
            display: flex;
            color: rgb(31, 53, 102);
            margin-top: 1rem;
            .icon-section {
                min-width: 48px;
            }
            h3 {
                margin-top: 0;
            }
            .item-number {
                padding: 6px 12px 8px;
                border-radius: 50%;
                background-color: rgb(31, 53, 102);
                color: #fff;
                font-size: 22px;
                line-height: 1;
                display: inline-block;
                margin-top: 4px;
            }
            .icon-img {
                max-width: 36px;
            }
            .image-container {
                position: relative;
            }
            .admin-signup-img-mobile {
                display: none;
            }
            .embed-container {
                max-width: 420px;
                width: 100%;
                > div {
                    max-width: 100%;
                }
            }
        }
        .ui.accordion {
            .title {
                display: none;
            }
            .content {
                margin-top: 0;
            }
        }
        .info-grey {
            color: #666;
            .info-title {
                color: #666;
            }
        }
        .ui.form input[type='text'],
        .ui.form input[type='password'] {
            border-left: none;
            border-right: none;
            border-top: none;
            border-width: 2px;
            border-radius: 0;
        }
        .admin-signup-img {
            max-height: 400px;
        }
        .link-to-alexa-img {
            max-height: 480px;
            max-width: 100%;
        }
        .step-3-arrow {
            max-width: 80px;
            vertical-align: middle;
            margin-right: 20px;
        }

        .step-3-img {
            max-width: 92%;
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
        }
        .max-320 {
            max-width: 320px;
        }
        .step-3-image-container {
            h4 {
                font-size: 1.2rem;
                color: #1f3566;
            }
            .link-alexa-img-container {
                display: flex;
                justify-content: space-between;
                .step-3-img {
                    margin-right: 18px;
                }
            }
            .click-link-container {
                align-items: center;
            }
        }
    }
    .flex {
        display: flex;
    }
    .flex-justify-center {
        justify-content: center;
    }
    .mt1 {
        margin-top: 1rem;
    }
    .position-relative {
        position: relative;
    }
    .question-icon {
        color: #e98530;
        font-size: 2.5rem;
        position: absolute;
        top: 0;
        right: 0;
    }
}
.signup-popup-content {
    padding: 8px;
    max-width: 380px;
}
.signup-popup-content.step-3 {
    max-width: 420px;
}
.pb-70 {
    padding-bottom: 70px;
}
.ui.basic.primary.button.log-out-btn {
    font-weight: 600 !important;
    border: none;
    padding: 0 4px;
    box-shadow: none !important;
}

@media screen and (max-width: 600px) {
    .signup-page-wrapper {
        .header {
            line-height: 1.2;
        }
        .content {
            margin-top: 2rem;
            .content-row {
                flex-wrap: wrap;
                > div {
                    min-width: 100%;
                    padding: 0;
                }
            }
            .admin-signup-img {
                max-width: 80px;
            }
            .image-container {
                padding: 16px 20px;
            }
            .step-3-image-container {
                padding: 0;
                max-width: calc(100vw - 62px);
                .step-3-img {
                    max-width: calc(100vw - 62px);
                }
            }

            .admin-signup-img-mobile {
                display: unset !important;
                float: right;
            }
            .info {
                .icon-section {
                    min-width: 40px;
                }
                .item-number {
                    padding: 6px 10px;
                    font-size: 18px;
                }
                .icon-section .icon-img {
                    max-width: 32px;
                }
            }
            .ui.accordion {
                .title {
                    display: unset;
                }
            }
        }
    }
    .signup-popup-content {
        padding: 8px;
        max-width: 86vw;
        font-size: 14px;
    }
}

@media screen and (max-width: 1400px) {
    .alexa-user-img {
        max-height: 320px !important;
    }
}
