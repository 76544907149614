.heading {
    text-align: center;
    font-size: 35px;
    margin-bottom: -10px;
}

.spacing {
    margin-top: 20px;
    margin-bottom: 20px;
}

.calendarContainer {
    .fc-header-toolbar .fc-today-button {
        text-transform: capitalize;
    }
}