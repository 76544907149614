.food-menu {
    .menu-item {
        margin-bottom: 16px;
        display: flex;
        border: 1px solid #dfdfdf;
        padding: 12px;
        border-radius: 4px;
        .content {
            flex: 1;
        }
        .menu-type {
            color: #34a6d1;
            font-weight: 600;
            text-transform: capitalize;
        }
        .title {
            font-weight: 600;
            font-size: 1.1em;

            .dangerous-html {
                p {
                    margin-bottom: 0;
                }
            }
        }
        .time {
            color: #8c8c8c;
        }
        .icon {
            color: #777;
        }
    }
    .pane {
        border: none !important;
        padding: 0;
        font-size: 1em;
    }
    .filters-dropdown {
        display: flex;
        justify-content: flex-end;
    }
    .create-menu {
        .quill {
            height: 70px;
            max-width: 660px;
            margin-bottom: 10px;
        }
        .fields {
            display: flex;
            > div {
                margin: 0 8px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
}
