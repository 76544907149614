* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
	font-size: 11px !important;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Arial', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	/* left: 50%;
	transform: translateX(-50%); */
	margin: 0 auto !important;
}
#root{
	margin: 0 auto !important;
}
body .ui.inverted.dimmer {
	background-color: rgb(255, 255, 255);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media only screen and (min-width: 600px) {
	html {
		font-size: 12px !important;
	}
}

@media only screen and (min-width: 768px) {
	html {
		font-size: 14px !important;
	}
}

@media only screen and (min-width: 992px) {
	html {
		font-size: 16px !important;
	}
}

