.invite-alexa-user {
    .invite-resident {
        max-width: 520px;
        .title {
            margin-bottom: 12px;
            font-size: 1.2em;
        }
        .name-section {
            display: flex;
            flex-wrap: wrap;
            .field {
                flex: 1;
                // margin-right: 8px;
            }
            input[name='FirstName'] {
                margin-right: 8px;
            }
        }
        .field,
        textarea {
            margin-bottom: 8px;
        }
        .invite-button {
            margin-top: 1em;
        }
    }
    .alexa-user-invites {
        margin-top: 2.5em;
        margin-bottom: 4em;
        .invite {
            display: flex;
            border-bottom: 1px solid #ddd;
            margin-bottom: 1em;
            padding: 8px 0;
            align-items: center;
            .details {
                flex: 1;
                font-size: 1.1em;
                margin-right: 8px;
                .name {
                    font-weight: 600;
                    margin-bottom: 4px;
                }
                .timestamp {
                    color: #777;
                    font-size: 0.86em;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        .alexa-user-invites {
            .invite {
                flex-wrap: wrap;
                padding-top: 0px;
                .details {
                    flex-basis: 100%;
                    font-size: unset;
                }
                .action {
                    margin-top: 4px;
                }
            }
        }
        .name-section {
            margin-bottom: 8px;
            input[name='FirstName'] {
                margin-right: 0px;
            }
        }
    }
}
