.ListItem {
  display: flex;
  padding: 10px 0 !important;
}
.ListItem .description p,
.ListItem .description ol {
  margin: 0 !important;
}
.ListItem .meta {
  display: inline-block;
}
@media screen and (max-width: 700px) {
  .ListItem .list-item-time {
    display: block;
    width: 100%;
  }
}
.ListItem .meta_options_column {
  display: inline-block;
  margin-right: 20px !important;
  color: blue !important;
  cursor: pointer;
}
.ListItem .meta_options_column button {
  min-height: 0 !important;
  margin: 0;
  border-radius: 50% !important;
  padding: 0 !important;
  width: 45px;
  height: 45px;
  font-size: 12px !important;
  font-weight: normal;
  color: #ffffff;
}
.ListItem .meta_options_column button:hover {
  opacity: 0.7;
}
.ListItem .meta_options_column:nth-child(3) button {
  background: #5cb85c;
}
.ListItem .meta_options_column:nth-child(4) button {
  background: #337ab7;
}
.ListItem .meta_options_column:nth-child(5) button {
  background: #5bc0de;
}
.ListItem .meta_options_column:hover {
  color: red !important;
}
.ListItem .list-item-time {
  margin-right: 30px !important;
}
