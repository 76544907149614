.container {
    display: flex;
    justify-content: center;
    align-content: center;
    .content-disp {
        margin-top: 50%;
        font-size: 25px;
        p {
            margin-top: 10px;
        }
    }

    .auto-login-loader {
        margin-top: 80%;
        font-size: 25px;
    }

    // .content-disp:after {
    //     overflow: hidden;
    //     display: inline-block;
    //     vertical-align: bottom;
    //     -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    //     animation: ellipsis steps(4, end) 900ms infinite;
    //     content: '\2026'; /* ascii code for the ellipsis character */
    //     width: 0px;
    // }

    // @keyframes ellipsis {
    //     to {
    //         width: 20px;
    //     }
    // }

    // @-webkit-keyframes ellipsis {
    //     to {
    //         width: 20px;
    //     }
    // }
}
