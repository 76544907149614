@import '../../../semantic-ui/site/globals/site.variables';

.header {
    width: 100%;
    padding: 0 1.2rem 0;
    position: relative;

    .resident-links {
        overflow-x: auto;
        white-space: nowrap;
        padding: 15px 0;
        padding-bottom: 8px;
        margin-bottom: 4px;
        display: flex;

        .name-image-container {
            display: inline-flex;
            max-width: 80px;
            width: 76px;
            margin: 0 4px;
            flex-direction: column;
            align-items: center;
        }
        .name-image-container.disabled {
            .resident-image-container {
                border-color: grey;

                .resident-image,
                .relative-avatar {
                    filter: blur(1px);
                    opacity: 0.95;
                }
            }
            .registrant-name-header {
                color: rgb(180, 180, 180);
            }
        }
        .name-image-container.disabled-for-paid {
            .resident-image-container {
                border-color: grey;

                .resident-image,
                .relative-avatar {
                    filter: blur(1px);
                    opacity: 0.95;
                }
            }
            .resident-image-container::after {
                content: '!';
                color: red;
                font-size: 1.8rem;
                position: absolute;
                bottom: -2px;
                right: 0px;
            }

            .registrant-name-header {
                color: rgb(180, 180, 180);
            }
        }
        .resident-image-container {
            display: inline-block;
            border: 4px solid rgb(200, 200, 200);
            border-radius: 50%;
            width: 13vw;
            height: 13vw;
            max-width: 60px;
            max-height: 60px;
            position: relative;
            cursor: pointer;
            margin: 0 auto;

            img {
                display: inline-block;
                height: 100%;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                user-select: none;
            }
        }

        .active-registrant {
            border-color: rgb(255, 210, 1);
        }

        .image-dimmer {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: rgba(200, 200, 200, 0.5);
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            cursor: not-allowed;
        }
        .relative-avatar {
            background-color: #b385ea;
            font-weight: 600;
            border-radius: 50%;
            display: inline-flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 1.1rem;
            text-transform: capitalize;
            position: absolute;
        }

        // .registrant-disabled::after {
        // 	content: '';
        // 	display: block;
        // 	box-sizing: border-box;
        // 	width: 100%;
        // 	height: 100%;
        // 	border: 4px solid black;
        // 	border-radius: 50%;
        // 	background: rgba(0, 0, 0, 1);
        // }
        .resident-image-bk {
            background-repeat: no-repeat;
            background-size: cover !important;
            height: 100%;
            border-radius: 50%;
            flex: 1;
        }
        .registrant-name-header {
            overflow-x: hidden;
            text-overflow: ellipsis;
            max-width: 80px;
        }
        [data-tooltip]:after {
            padding: 0.2em 0.4em;
            font-size: 0.9rem;
        }
        [data-tooltip]:before {
            font-size: 0.9rem;
        }
    }

    .headerToolbar {
        display: flex;
        padding-bottom: 15px;
        margin: 0 0;
        border-bottom: 3px solid @secondaryColor;
        align-items: center;
        position: relative;
        justify-content: space-between;

        .page-name {
            flex-grow: 1;
            font-size: 18px;
            color: @primaryColor;
            text-align: center;
            margin: 0;
            position: absolute;
            width: 100%;
            z-index: -1;
        }

        .right {
            .nav-item {
                margin-right: 10px;
            }
        }

        .invite-count {
            background-color: #34a6d1;
            padding: 2px 6px;
            border-radius: 50%;
            line-height: 1;
            margin-left: 2px;
        }
        .no-display {
            display: none;
        }
        .username.has-invites {
            position: relative;
            div.text::after {
                content: '';
                padding: 3px;
                border-radius: 50%;
                background-color: #34a6d1;
                position: absolute;
                top: 0;
            }
        }
        @media screen and (max-width: 480px) {
            .page-name {
                font-size: 1em;
            }
            .dropdown.icon {
                margin-left: 4px;
            }
            .ui.dropdown .text {
                max-width: 98px;
                font-size: 0.8em;
                line-height: 1.14;
                text-overflow: ellipsis;
            }
        }
    }

    .ui.inverted.menu {
        background-color: @primaryColor;
        z-index: 1001;
    }

    #divider {
        border-color: @secondaryColor;
        margin-bottom: 0;
    }
    .ui.loader.header-loader {
        position: absolute;
        top: 50%;
        left: 22%;
        width: 100%;
        margin-top: -12px;
    }
}

.small-image {
    width: 2vw; /* Adjust size of the container */
    height: 2vw; /* Adjust size of the container */
    border-radius: 50%; /* Create circular container */
    overflow: hidden; /* Hide overflow content */
    position: relative;
}

.small-image img {
    width: 100%;/* Make image fill the container */
    height: 100%;/* Make image fill the container */
    object-fit: cover;/* Ensure image fills container without stretching */
    border-radius: 50%;/* Create circular image */
}

.bars.icon {
    font-size: 32px !important;
    padding-top: 5px;
    color: #0E8ED7;
}